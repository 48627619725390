import { Component } from "react";
import { NavLink } from "react-router-dom";
import userImage from '../asset/images/default_user.png';
import parse from "html-react-parser";
import axios from "axios";
import config from '../config.json'

export default class NewFriends extends Component{

    constructor()
    {
        super()
        this.state = {
            search: '',
            users: []
        }
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/users`)
            .then(res => {
                this.setState({
                    users: res.data
                })
                //console.log(res.data)
            })
    }

    calculateAge = birth =>{
        if( birth !== undefined ){
            let today = new Date();
            let birthDate = new Date(birth);
    
            let age_now = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if( m < 0 || (m === 0 && today.getDate() < birthDate.getDate()) ){
                age_now--;
            }
            return(age_now)
        }else{
            return null
        }
    }

    renderUsersList(users, index){
        let image = (users.user_avatar === undefined || users.user_avatar[0] === "") ? userImage:users.user_avatar;
        return(
            <div className="u-col-12 u-col-md-5 px-3 mb-5 new_friends" key={index}>
                <div className="user_prev d-flex">
                    <div className="u-col-6">
                        <img src={image} className="avatar" alt={users.user_nick} />
                    </div>
                    <div className="user_data u-col-6 px-2">
                        <strong>{users.user_nick}</strong>
                        <ul>
                            {(users.user_birth === undefined || users.user_birth[0] === "")?'':parse(`<li>${this.calculateAge(users.user_birth[0])} lat</li>`)}
                            {(users.user_sex === undefined || users.user_sex['label'] === ""|| users.user_sex[0] === "")?'':parse(`<li>${users.user_sex['label']}</li>`)}
                            {(users.user_city === undefined || users.user_city[0] === "")?'':parse(`<li>${users.user_city[0]}</li>`)}
                            {(users.user_country['label'] === undefined || users.user_country['label'] === "")?'':parse(`<li>${users.user_country['label']}</li>`)}
                        </ul>
                        <NavLink to={`/users/${users.id}`}>
                            Obejrzyj profil
                        </NavLink>
                    </div>
                </div>
            </div>
        );

    }
    onchange = e => {
        this.setState({ search: e.target.value });
    };
    render(){
        const { search } = this.state;
        /* const filteredUsers = this.state.users.filter(users => {
            return users.user_nick.toLowerCase().indexOf(search.toLowerCase()) !== -1
        }); */
        /* const filteredUsers = this.state.users.filter(users => {
            return users.user_nick.toLowerCase().indexOf(search.toLowerCase()) !== -1
        }); */
        //console.log( this.state.users )
        return(
            <>
            <div className="text-center my-4 premium">
                PREMIUM
                <p>Wybierz wariant premium aby wyszukać znajomych nicku więcej niż 5 razy w miesiącu.</p>
            </div>
            <div className="mb-5">
                <div className="text-center">
                    <input type="text" placeholder="Wpisz nick" onChange={this.onchange}/>
                </div>
                <button className="link-button my-4">otwórz ustawienia szczegółowego wyszukiwania&nbsp;<span className="af-arrow-right"></span></button>
                <div className="u-row">
                    {/* {filteredUsers.map(users => {return this.renderUsersList(users);})} */}
                    {this.state.users.map((users,index) => {return this.renderUsersList(users,index);})}
                </div>
            </div>
            </>
        )
    }
}