import React,{ Component } from "react";
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';
import parse from "html-react-parser";
import Avatar from "./Avatar";
import config from '../config.json'

export default class PostItem extends Component{
    state = {
        author: '',
        commentsCount: 0,
        likesCount: 0,
        isLoaded: false
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/comments/${this.props.post.post_ID}`)
            .then(res => {
                if( res.data !== "" ){
                    this.setState({
                        commentsCount:res.data.length
                    })
                }
            })
            .catch(
                err => {console.info( err )}
            )
    }

    makeHumanDate(date){
        date = date.split("-")
        var month = ''
        switch( date[1] ){
            case "01":
                month = "STY"
            break;
            case "02":
                month = "LUT"
            break;
            case "03":
                month = "MAR"
            break;
            case "04":
                month = "KWI"
            break;
            case "05":
                month = "MAJ"
            break;
            case "06":
                month = "CZE"
            break;
            case "07":
                month = "LIP"
            break;
            case "08":
                month = "SIE"
            break;
            case "09":
                month = "WRZ"
            break;
            case "10":
                month = "PAŹ"
            break;
            case "11":
                month = "LIS"
            break;
            case "12":
                month = "GRU"
            break;
            default:
                month = "STY"
            break;
        }
        return date[2]+" "+month+" "+date[0]
    }

    showPost = () =>{
        <Redirect to={`/post/${this.props.post.post_ID}`} />
    }

    handleChange = () =>{
        //likes++;
        //console.log( 'dodaj like do: '+this.props.post.post_ID+', '+this.props.post.likes )
    }

    render(){
        const { post_ID, title, content, author, created, likes, image } = this.props.post
        var data = created.split(" ")
        var dataMsg = this.makeHumanDate(data[0])
        var timeMsg = data[1]
        return(
            <div className="u-col-12 u-col-md-6">
                <div className="post-card text-card">
                    <header>
                        <div>
                            {dataMsg}
                        </div>
                        <div className="text-right">
                            <span className="af-clock"></span>&nbsp;{timeMsg}<br/>
                        </div>
                    </header>
                    <aside>
                        <Link to={`/post/${post_ID}`}><h2>{title}</h2></Link>
                        {(content === null)?null:parse(content.toString())}
                    </aside>
                    <footer className="align-items-center">
                        <div>
                            <Avatar user_id={author} />
                        </div>
                        <div className="card-meta text-right">
                            <button onClick={this.handleChange}><span className="af-heart"></span>&nbsp;{likes}</button>
                            <Link to={`/post/${post_ID}`}><span className="af-comments"></span>&nbsp;{this.state.commentsCount}</Link>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}