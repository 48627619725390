import {Redirect} from "react-router-dom";

export default function LogOut()
{
    if(localStorage.length > 0){
        localStorage.clear()
        window.location.reload(true);
    }else{
        return <Redirect to="/login" />
    }
}