import axios from "axios";
import React,{ Component, useState } from "react";
import {
    useHistory, Redirect, NavLink
  } from "react-router-dom";
import config from '../config.json'

export default class LogedIn extends Component {

    constructor( props ){
        super( props )

        this.state = {
            username:'',
            password: '',
            userName: '',
            token: '',
            loggedIn: false,
            isLoading: false
        }
    }

    onFormSubmit = (event) => {
        event.preventDefault();

        const siteUrl = config.dbURL;

        let history = useHistory;

        const loginData = {
            "username": this.state.username,
            "password": this.state.password
        };

        this.setState( {isLoading:true}, () => {
            axios.post( 
                `${siteUrl}/wp-json/jwt-auth/v1/token`,
                loginData
             ).then( res => {
                console.log( res.data )
                if( undefined === res.data.token ){
                    //error no access
                    return;
                }else{
                    localStorage.setItem( 'token', res.data.token );
                    localStorage.setItem( 'userName', res.data.user_display_name );
                    localStorage.setItem( 'userEmail', res.data.user_email );
                    localStorage.setItem( 'userloggedIn', true );
                    this.setState({
                        isLoading: false,
                        token: res.data.token,
                        userName: res.data.user_display_name,
                        loggedIn: true
                    })
                    axios.get(`${siteUrl}/wp-json/fac/v1/user/${res.data.user_nicename}`).then( response => {
                        //console.info('user ID:'+response.data)
                        localStorage.setItem( 'userID', response.data );
                        window.location.reload(true);
                    } )
                    axios.get(`${siteUrl}/wp-json/fac/v1/users/${localStorage.getItem('userID')}`).then( response => {
                        console.info('user ID:'+response.data)
                        localStorage.setItem( 'fullData', response.data.user_active );
                        if( res.data.user_nick !== undefined && res.data.user_nick !== '' ){
                            localStorage.setItem( 'userName', res.data.user_nick );
                        }
                        window.location.reload(true);
                    } )
                }
             })
             .catch( err => {
                 console.log(err)
             } )
        } );
        //window.location.reload(true);
    }

    handleOnChange = ( event ) => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        )
    }

    render(){

        const { username, password } = this.state;
        if( this.state.loggedIn ){
            //window.location.reload(true);
            return <Redirect to='/all-posts'/>
        }else{
            return(
                <div className="login_form">
                    <div className="form">
                        <h2>Zaloguj się</h2>
                        <form onSubmit={this.onFormSubmit} >
                            <label htmlFor="username">Adres e-mail</label>
                            <input 
                                type="text" 
                                name="username" 
                                id="username" 
                                value={username}
                                onChange={this.handleOnChange}
                            /><br/>
                            <label htmlFor="password">Hasło</label>
                            <input 
                                type="password" 
                                name="password" 
                                id="password" 
                                value={password}
                                onChange={this.handleOnChange}
                            /><br/>
                            <button type="submit">
                                Zaloguj
                            </button>
                        </form>
                        <p className="text-center">
                            <a href={`${config.pageURL}/rejestracja`}  rel="noreferrer">
                                Nie pamiętasz hasła?
                            </a>
                        </p>
                        <p className="text-center">
                            Jesteś nowym użytkownikiem Feelio?&nbsp;
                            <a href={`${config.pageURL}/rejestracja`}  rel="noreferrer">
                                Dołącz teraz!
                            </a>
                        </p>
                        <p>
                            <a href={config.pageURL}  rel="noreferrer" className="back">
                                <span className="af-arrow-right icon-rotate-x"></span> powrót
                            </a>
                        </p>
    
    
                    </div>
                </div>
            );
        }
    }
}