import { Component } from "react";
import axios from "axios";
import userImage from '../asset/images/default_user.png';
import config from '../config.json'

export default class Avatar extends Component{

    constructor(props){
        super(props)
        this.state = {
            userName: '',
            userImgUrl: ''
        }
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/users/${this.props.user_id}`)
            .then(res =>{
                //console.info(res.data)
                if( res.data.user_avatar[0] === "" ){
                    this.setState({
                        userName:res.data.user_nick,
                        userImgUrl:userImage
                    })
                }else{
                    this.setState({
                        userName:res.data.user_nick,
                        userImgUrl:res.data.user_avatar
                    })
                }
            })
            .catch(err => {
                console.warn(err)
            })
    }

    render(){
        //console.log( this.state );
        return(
            <div className="avatar">
                <div className="image">
                    <img src={this.state.userImgUrl} alt={this.state.userName} />
                </div>
                <div className="name">
                    <h5 className="user_name">{this.state.userName}</h5>
                </div>
            </div>
        )
    }
}