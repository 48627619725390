import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import useSocket from 'use-socket.io-client';
import axios from "axios";
import userImage from '../asset/images/default_user.png';
import config from '../config.json'

export default function Room(props){
    
    const [toName, setToName] = useState("");
    const [fromName, setFromName] = useState("");
    const [toAvatar, setToAvatar] = useState("");
    const [fromAvatar, setFromAvatar] = useState("");
    const [messages, setMessages] = useState([]);
    const [fromID] = props.match.params.from_id;
    const [toID] = props.match.params.to_id;

    axios
        .get(`${config.dbURL}/wp-json/fac/v1/users/${props.match.params.to_id}`)
        .then(res=>{
            setToName( res.data.user_nick[0] );
            setToAvatar( res.data.user_avatar[0] );
        })
    axios
        .get(`${config.dbURL}/wp-json/fac/v1/users/${props.match.params.from_id}`)
        .then(res=>{
            setFromName( res.data.user_nick[0] );
            setFromAvatar( res.data.user_avatar[0] );
        })

    const [socket] = useSocket('http://localhost', {path:'/socket'});
    //socket.connect();

    const roomNumber = 'private'+ props.match.params.from_id + props.match.params.to_id

    const sendMessage = () => {
        socket.emit("from_chat",{ fromID, roomNumber, messages })
    }

    useEffect(() => {
        socket.on("message", (data) => {
            messages.push({
                user: data.user,
                contant: data.content
            })
        })
    }, [socket])

    return(
        <div className="account_box px-5 pt-3 pb-4">
                <NavLink to="/chat" className="link-button">
                    <span className="af-arrow-right icon-rotate-x">POWRÓT</span>
                </NavLink>
                <header>
                Pokuj czatu nr: {roomNumber} pomiędzy user id: {props.match.params.from_id} a user id {props.match.params.to_id}<br/>
                {toName}
                </header>
                <div className="chat-messages">
                    {messages.map( (data) => {
                        if( data.user === fromID ){
                            return(
                                <div className="message">
                                    <img src={fromAvatar} alt={fromName} />
                                    <p>{data.content}</p>
                                </div>
                            )
                        }else{
                            return(
                                <div className="message message-right">
                                    <img src={(toAvatar === '')?userImage:toAvatar} alt={toName} />
                                    <p>{data.content}</p>
                                </div>
                            )
                        }
                    } )}
                </div>
                <div className="chat-form">
                    <input 
                        type="text"
                        placeholder="Napisz wiadomość"
                    />
                    <button className="af-arrow-right link-button" onClick={sendMessage}></button>
                </div>
            </div>
    );
}