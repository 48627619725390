import React,{ Component } from "react";
import axios from "axios";
import Avatar from "./Avatar";
import Emoticon from "./Emoticon";
import 'emoji-mart/css/emoji-mart.css'
import config from '../config.json'


export default class CreatePost extends Component{

    constructor(props){
        super(props)
        this.state = {
            post_title:'',
            post_content:'',
            showEmoji:false
        }
    }

    /* componentDidUpdate(){
        this.setState({

        })
    } */

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
          [name]: value
        })    
    }

    textType = event => {
        this.setState({
            post_content:event.target.innerHTML
        })

        //console.log(event.target.innerHTML)
    }

    insertPost = e => {
        e.preventDefault()
        const newPost = {
            author: localStorage.getItem('userID'),
            title: this.state.post_title,
            content: this.state.post_content,
            image: null
        };
        console.log( newPost );
        axios.post(`${config.dbURL}/wp-json/fac/v1/post/`, newPost).then( res => {
            this.props.history.push('/');
        })
    }

    handleBack = () => {
        this.props.history.push('/');
    }
    addEmoji = () =>{
        
    }
    showEmoji = () =>{
        const currentState = this.state.activeSettings;
        this.setState({
            showEmoji: !currentState,
        });
    }
    render(){
        
        return(
            <div className="new_post_form">
                <div className="u-row align-items-center">
                    <div className="u-col-6">
                        <Avatar user_id={localStorage.getItem('userID')} />
                    </div>
                    <div className="u-col-6 text-right">
                        <button className="close go-back" aria-label="Zamknij" onClick={this.handleBack}>
                            <span className="af-cancel"></span>
                        </button>
                    </div>
                </div>
                <input type="text" name="post_title" value={this.state.post_title} placeholder="Wpisz tytuł posta" onChange={this.handleChange}/>
                <textarea name="post_content" value={this.state.post_content} placeholder="Kliknij aby zacząć pisać treść posta..." readOnly></textarea>
                <div className="textarea" contentEditable={true} onInput={this.textType}></div>
                <div className="post-tools">
                    <button title="Dodaj zdjęcie"><span className="af-camera"></span></button>
                    <button className={(this.state.showEmoji)?'emoticon active':'emoticon'} title="Dodaj emotikon" onClick={this.showEmoji}><span className="af-smile"></span><Emoticon/></button>
                </div>
                <div className="text-right">
                    <button className="goforward" type="submit" aria-label="Opublikuj" onClick={this.insertPost}>Opublikuj&nbsp;<span className="af-arrow-right"></span></button>
                </div>
            </div>
        );
    }
}