import { Component } from "react";
import axios from "axios";
import ImagesUploader from 'react-images-uploader';
//import {express, response} from 'express';
import corsPrefetch from 'cors-prefetch-middleware';
import imagesUpload from 'images-upload-middleware';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';

const baseUri = window.location.href.split('/')[0]+'//'+window.location.href.split('/')[2];


export default class MyGallery extends Component{
    
    render(){
        const uploadLocation = baseUri+'/upload/';

        return(
            <div className="account_box">
                <ImagesUploader 
                    url={uploadLocation}
                    optimisticPreviews
                    label=""
                    onLoadEnd={(err) => {
                        if(err){
                            console.log(err)
                        }
                        var formData = new FormData();
                        var imagefile = document.querySelector('#filesInput');
                        formData.append("image", imagefile.files[0]);
                        axios.post('/my-gallery', formData, {
                            headers: {
                            'Content-Type': 'multipart/form-data'
                            }
                        })
                    }}
                />
            </div>
        )
    }
}