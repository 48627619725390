function Step1(props)
{
if( props.currentStep !== 1 ){
    return null
}
    return(
        <div>
            <h3 className="header">Wpisz swój nick</h3>
            <p className="lead">Nick będzie widoczny dla innych użytkowników oraz będzie pełnił funkcję loginu.</p>
            <div>
                <input type="text" name="user_nick" value={props.user_nick} onChange={props.handleChange} required></input>
            </div>
        </div>
    );
}

export default Step1;