import React from 'react';
//import avatar from '../default_user.png';
import Avatar from 'react-avatar-edit';

//const apiAuth = window.wpApiSettings;

function Step6(props)
{
    if( props.currentStep !== 6 ){
        return null
    }
    //const [preview, setPreview] = useState();
    /* function onClose(){
        props.user_avatar = null;
    } */
    /* function onCrop(elem){
        props.user_avatar = elem.target.value;
    } */
    function onBeforeFileLoad(elem){
        if( elem.target.files[0].size > 500000 ){
            alert("File is too big!");
            elem.target.value = "";
        }
    }
    return(
        <div>
            <h3 className="header">Twój avatar</h3>
            <p className="lead">Dodaj swoje zdjęcie profilowe, które będą widzieć inni użytkownicy!</p>
            <div className="custom_file file_avatar mt-4 mb-5">
                <label>
                    <Avatar
                        width={121}
                        height={121}
                        //onCrop={onCrop}
                        //onClose={onClose}
                        label=""
                        onBeforeFileLoad={onBeforeFileLoad}
                        src={props.user_avatar}
                    />
                    <span className="material-icons btn_add">
                        add
                    </span>
                </label>
            </div>
            <p className="lead">Zdjęcie nie może mieć więcej niż 5MB.</p>
        </div>
    );
}

export default Step6;