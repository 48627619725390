import { Component } from "react";

export default class ReportUser extends Component{

    state = {
        user_nick: 'John Doe',
        email: 'john@doe.com',
        subject: '',
        message: '',
        acceptance: ''
    }

    handleChange = e =>{
        const {name, value} = e.target;
    }

    sendForm = e =>{
        e.preventDefault();
        console.log('wyślij wiadomość')
    }

    render(){
        return(
            <div className="account_box py-4">
                    <h1 className="text-center">Zgłoszenie łamania regulaminu </h1>
                    <p className="text-center">Wypełnij formularz aby zgłosić łamanie zasad regulaminu Feelio przez użytkownika portalu.</p>
                    <div className="box_shadow p-5">
                        <form className="full-form">
                            <label className="d-block">
                                Twój nick<br/>
                                {this.state.user_nick}
                                <input type="hidden" name="user" value={this.state.user_nick}/>
                                <input type="hidden" name="email" value={this.state.email}/>
                            </label>
                            <label htmlFor="subject" className="d-block">
                                Temat
                            </label>
                                <input name="subject" type="text" placeholder="Problem techniczny" className="d-block" value={this.state.subject} onChange={this.handleChange} />
                            <label htmlFor="message" className="d-block">
                                Wiadomość
                            </label>
                                <textarea name="message" placeholder="" className="d-block" value={this.state.message} onChange={this.handleChange}></textarea>
                            <div className="custom_checkbox">
                                <input type="checkbox" name="acceptance" id="acceptance"/>
                                <label htmlFor="acceptance">
                                    Zapoznałem się z informacją o administratorze i przetwarzaniu danych
                                </label>
                            </div>
                            <div className="text-md-right">
                                <button className="send" onClick={this.sendForm} type="submit">Wyślij</button>
                            </div>
                        </form>
                    </div>
                </div>
        )
    }
}