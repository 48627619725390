import { Component } from "react";

export default class Emoticon extends Component{

    constructor(props){
        super(props)
        this.state = {
            images: [
                {
                    src: '../asset/emoji/2021_feelio_emoticon – 1.png',
                    name: 'emoji1'
                },
                {
                    src: '../asset/emoji/2021_feelio_emoticon – 2.png',
                    name: 'emoji2'
                }
            ]
        }
    }

    selectEmoji = eavent => {
        console.log( eavent.target )
    }

    render(){
        const emoji = [
            {
                src: '../../../asset/emoji/2021_feelio_emoticon – 1.png',
                name: 'emoji1'
            },
            {
                src: '/asset/emoji/2021_feelio_emoticon – 2.png',
                name: 'emoji2'
            },
            {
                src: '//placehold.it/32x32',
                name: 'emoji3'
            }
        ]
        return(
            <div className="emoticon_popup">
                {emoji.map( (icon,index) => (<img key={index} src={icon.src} alt={icon.name} onClick={this.selectEmoji}/>) )}
            </div>
        )
    }
}