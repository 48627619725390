import { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import heart from '../asset/images/heart-outline.svg';
import config from '../config.json'

export default class MyAccount extends Component{
    constructor(props){
        super(props)
        this.state = {
            userImgUrl: '',
            userMeta: [],
            userDesc:''
        }
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/wp/v2/users/${localStorage.getItem('userID')}`)
            .then(res =>{
                this.setState({
                    userImgUrl:res.data.avatar_urls[96],
                    userMeta:res.data.meta,
                    userDesc:res.data.description
                })
                console.log(res.data.meta)
            })
            .catch(err => {
                console.warn(err)
            })
    }

    calculateAge = birth =>{
        if( birth !== undefined ){
            let today = new Date();
            let birthDate = new Date(this.state.userMeta.user_birth);
    
            let age_now = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if( m < 0 || (m === 0 && today.getDate() < birthDate.getDate()) ){
                age_now--;
            }
            return(age_now)
        }else{
            return null
        }
    }
    emptyData = () => {
        return(
            <span className="hidden-data">
                Ukryte
            </span>
        )
    }
    drawGallery = gallery =>{
        const baseUri = window.location.href.split('/')[0]+'//'+window.location.href.split('/')[2];
        return(
            <div className="u-col-12 u-col-md-10 mx-md-auto">
                <ul className="gallery-items">
                    {gallery.map( (image, index) => <li key={index}><img src={`${baseUri}/photo/${image}`} alt={`Gallery item ${index}`} /></li> )}
                </ul>
                <div className="text-center">
                    <NavLink to="/my-gallery" className="link-button">zobacz galerię<span className="af-arrow-right"></span></NavLink>
                </div>
            </div>
        )
    }

    render(){
        const baseUri = window.location.href.split('/')[0]+'//'+window.location.href.split('/')[2];
        return(
            <div className="account_box">
                <section className="about">
                    <div className="u-row">
                        <div className="u-col-12 u-col-md-3 offset-md-1">
                            <div className="avatar">
                                <img src={this.state.userImgUrl} alt={localStorage.getItem('userName')}/>
                            </div>
                        </div>
                        <div className="u-col-12 u-col-md-7">
                            <span className="user_name">{localStorage.getItem('userName')}</span>
                            <h2>Kilka słów o mnie...</h2>
                            <p>
                                {this.state.userDesc}
                            </p>
                        </div>
                    </div>
                </section>
                <section className="skills" style={{backgroundImage:heart}}>
                    <div className="heart"><img src={heart} alt="heart"/></div>
                    <div className="u-row">
                        <div className="u-col-12 u-col-md-10 mx-md-auto">
                            <ul className="skills-items">
                            {(this.state.userMeta.user_sex === undefined || this.state.userMeta.user_sex.length === 0)?null:parse(`<li>${this.state.userMeta.user_sex}</li>`) }
                            {(this.state.userMeta.user_birth === undefined || this.state.userMeta.user_birth.length === 0)?null:parse(`<li>${this.calculateAge(this.state.userMeta.user_birth)}</li>`) }
                            {(this.state.userMeta.user_city === undefined || this.state.userMeta.user_city.length === 0)?null:parse(`<li>${this.state.userMeta.user_city}</li>`) }
                            {(this.state.userMeta.user_country === undefined || this.state.userMeta.user_country.length === 0)?null:parse(`<li>${this.state.userMeta.user_country}</li>`) }
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="gallery">
                    <div className="u-row">
                        {(this.state.userMeta.user_gallery === undefined || this.state.userMeta.user_gallery.length === 0)?null:this.drawGallery( this.state.userMeta.user_gallery ) }
                    </div>
                </section>
                <section className="interest">
                    <div className="u-row">
                        <div className="u-col-12 u-col-md-10 mx-md-auto">
                            <h5 className="section-title">Zainteresowania</h5>
                            <ul className="interest-items">
                                {(this.state.userMeta.user_interest === undefined)?null:this.state.userMeta.user_interest.map( (interest, index) => <li key={index}>{interest}</li> )}
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="info">
                    <div className="u-row">
                        <div className="u-col-12 u-col-md-10 mx-md-auto">
                            <ul className="info-items">
                                <li><img src={`${baseUri}/zodiak/${this.state.userMeta.user_zodiak}.png`} alt={this.state.userMeta.user_zodiak}/></li>
                                <li>
                                    <div className="info-item">
                                        <h5>Znajomość języków obcych</h5>
                                        <p>{(this.state.userMeta.user_language === undefined || this.state.userMeta.user_language.length === 0)?this.emptyData():this.state.userMeta.user_language.map( (language, index ) => <span key={index}>{language}</span>) }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Twoja narodowość</h5>
                                        <p>{(this.state.userMeta.user_country === undefined || this.state.userMeta.user_country.length === 0)?this.emptyData():this.state.userMeta.user_country }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Wyznanie</h5>
                                        <p>{(this.state.userMeta.user_religion === undefined || this.state.userMeta.user_religion.length === 0)?this.emptyData():this.state.userMeta.user_religion }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Szukam...</h5>
                                        <p>{(this.state.userMeta.user_goal === undefined || this.state.userMeta.user_goal.length === 0)?this.emptyData():this.state.userMeta.user_goal }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Interesują Cię...</h5>
                                        <p>{(this.state.userMeta.user_sex_intrest === undefined || this.state.userMeta.user_sex_intrest.length === 0)?this.emptyData():this.state.userMeta.user_sex_intrest }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Twój typ sylwetki</h5>
                                        <p>{(this.state.userMeta.user_body === undefined || this.state.userMeta.user_body.length === 0)?this.emptyData():this.state.userMeta.user_body }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Twój stan cywilny</h5>
                                        <p>{(this.state.userMeta.user_status === undefined || this.state.userMeta.user_status.length === 0)?this.emptyData():this.state.userMeta.user_status }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Zawód</h5>
                                        <p>{(this.state.userMeta.user_work === undefined || this.state.userMeta.user_work.length === 0)?this.emptyData():this.state.userMeta.user_work }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Czy masz dzieci?</h5>
                                        <p>{(this.state.userMeta.user_children === undefined || this.state.userMeta.user_children.length === 0)?this.emptyData():this.state.userMeta.user_children }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Wykształcenie</h5>
                                        <p>{(this.state.userMeta.user_education === undefined || this.state.userMeta.user_education.length === 0)?this.emptyData():this.state.userMeta.user_education }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Nałogi</h5>
                                        <p>{(this.state.userMeta.user_addictions === undefined || this.state.userMeta.user_addictions.length === 0)?this.emptyData():this.state.userMeta.user_addictions.map( (addictions, index ) => <span key={index}>{addictions}</span>) }</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}