import { Component } from "react";
import Tabs, { TabPane } from 'rc-tabs';
import axios from "axios";
import { NavLink } from "react-router-dom";
import Avatar from "./Avatar";
import { isRestElement } from "@babel/types";
import config from '../config.json'

export default class Chat extends Component{

    constructor()
    {
        super()
        this.state = {
            search: '',
            users: [],
            rooms:[],
            errors:[]
        }
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/users`)
            .then(res => {
                this.setState({
                    users: res.data
                })
            })
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/rooms/${localStorage.getItem('userID')}`)
            .then(res => {
                if( res.data.code === 'no_data' ){
                    this.setState({
                        errors:['info', 'Nie rozpocząłeś jeszcze żadnej konwersacji.']
                    })
                }else{
                    this.setState({
                        rooms: res.data
                    })
                }
            })
            .catch(err => {
                if( err.response.status === 400 ){
                    this.setState({
                        errors:['warning']
                    })
                }
                if( err.response.status === 401 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 404 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 500 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                this.setState({
                    errors:['warning']
                })
            })
    }

    renderUsersList(users, index){
        return(
            <div className="u-col-12 px-3 room_user_list" key={index}>
                <NavLink to={`/room/${localStorage.getItem('userID')}/${users.id}`}>
                    <Avatar user_id={users.id} />
                </NavLink>
            </div>
        );

    }
    renderUsersRooms(users, index){
        return(
            <NavLink to={`/room/${localStorage.getItem('userID')}/${users.id}`} className="d-flex room_list justify-content-between"  key={index}>
                <div className="u-col-12 u-col-md-6 px-3 ">
                    <Avatar user_id={users.id} />
                </div>
                <div className="u-col-12 u-col-md-3 px-3 status">
                    nowa wiadomość <span className="af-messages"></span>
                </div>
            </NavLink>
        );

    }

    render(){
        return(
            <div className="account_box px-5 pt-3 pb-4">
                <Tabs>
                    <TabPane tab="Czaty" key="1">
                        <div className="u-row mb-5">
                            {this.state.rooms.map( (users, index) => this.renderUsersRooms(users, index))}
                        </div>
                    </TabPane>
                    <TabPane tab="Osoby" key="2">
                        <div className="u-row mb-5">
                            {this.state.users.map( (users, index) => this.renderUsersList(users, index))}
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}