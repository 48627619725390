import './asset/bootstrap-reboot.css';
import './asset/bootstrap-grid.css';
import './asset/fonts/css/feelio.css';
import './App.css';
import Loader from './components/loader';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import axios from 'axios';
//wpisy//todo
import PostList from './components/PostsList';
import MyPosts from './components/MyPosts';
import CreatePost from './components/CreatePost';//todo image, emoticon
import PostView from './components/PostView';
//konto//todo
import MyAccount from './components/MyAccount';//todo correct data
import EditMyAccount from './components/EditMyAccount';
import MyGallery from './components/MyGallery';//todo
import Visit from './components/Visit';//todo //! PREMIUM
import CallIn from './components/CallIn';//todo table actions
import Callout from './components/Callout';
import Friends from './components/Friends';
//Nowi znajomi//todo
import NewFriends from './components/NewFriends';//todo search
//Chat//todo
import Chat from './components/Chat';//todo
import Room from './components/Room'//todo
//Powiadomienia//todo
import Notyfications from './components/Notyfications';//todo
//Premium//todo
import Subscribe from './components/Subscribe';//todo
import Settings from './components/Settings';//todo
//Ustawienia//todo
import ReportUser from './components/ReportUser';//todo
import ReportBug from './components/ReportBug';//todo
import { Component } from 'react';
//globals
import LogIn from './components/LogIn';//todo redirect
import LogOut from './components/LogOut';
import Onbording from './components/onbording';
import UserView from './components/UserView'//todo
//todo styling app
import config from './config.json'


export default class App extends Component {

  constructor(props){
    super(props);
    //this.addActiveClass = this.addActiveClass.bind(this);
    this.state = {
      loggedIn: false,
      fillData: 0,
      token: '',
      activeMain: true,
      activeProfile: false,
      activePremium: false,
      activeSettings: false,
      activeInfo: false,
      userImgUrl: '',
      apiResponse:''
    }
  }

  componentDidMount(){
    if(localStorage.getItem('userloggedIn')){
      this.setState({
        loggedIn:true
      })
    }else{
      this.setState({
        loggedIn:false
      })
    }
    axios
      .get(`${config.dbURL}/wp-json/fac/v1/users/${localStorage.getItem('userID')}`)
      .then(res =>{
          this.setState({
              userImgUrl:res.data.user_avatar,
              fillData:res.data.user_active
          })
      })
      .catch(err => {
          console.warn(err)
      })
  }

  toggleMainClass = () =>{
    const currentState = this.state.activeMain;
    this.setState({
      activeMain: !currentState,
      activeProfile: false,
      activePremium: false,
      activeSettings: false,
      activeInfo: false
    });
  }
  toggleProfileClass = () =>{
    const currentState = this.state.activeProfile;
    this.setState({
      activeMain: false,
      activeProfile: !currentState,
      activePremium: false,
      activeSettings: false,
      activeInfo: false
    });
  }
  togglePremiumClass = () =>{
    const currentState = this.state.activePremium;
    this.setState({
      activeMain: false,
      activeProfile: false,
      activePremium: !currentState,
      activeSettings: false,
      activeInfo: false
    });
  }
  toggleSettingsClass = () =>{
    const currentState = this.state.activeSettings;
    this.setState({
      activeMain: false,
      activeProfile: false,
      activePremium: false,
      activeSettings: !currentState,
      activeInfo: false
    });
  }
  toggleInfoClass = () =>{
    const currentState = this.state.activeInfo;
    this.setState({
      activeMain: false,
      activeProfile: false,
      activePremium: false,
      activeSettings: false,
      activeInfo: !currentState
    });
  }
  toggleViews = () =>{
    //console.log( localStorage.getItem('userloggedIn') );
    //console.log( this.state.fillData[0] );
    if( !localStorage.getItem('userloggedIn') ){
      return <Redirect to="/login" />
    }else if( localStorage.getItem('userloggedIn') && localStorage.getItem('fillData') !== '1' ){
      return <Redirect to="/onbording" />
    }
  }

  render(){
    const { loggedIn, fillData } = this.state;
    return(
      <Router>
          <div className="u-container">
            <div className="u-row">
                <div className={ this.state.loggedIn ?'u-col-12 u-col-md-3 navbar':'d-none'}>
                  <div className="user_data text-center">
                    <div className="user_avatar">
                      <img src={this.state.userImgUrl} alt={localStorage.getItem('userName')}/>
                    </div>
                    <div className="user_name text-center">
                      {localStorage.getItem('userName')}
                    </div>
                  </div>
                  <ul className={this.state.activeMain ? 'has-children active':'has-children'}>
                    <span role="button" onClick={this.toggleMainClass}><span className="icon af-component-3"></span>Główna</span>
                    <li>
                      <NavLink activeClassName="active" to="/all-posts">Pokaż wszystkie posty</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/my-posts">Zobacz swoje posty</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/create-post">Stwórz nowy post</NavLink>
                    </li>
                  </ul>
                  <ul className={this.state.activeProfile ? 'has-children active':'has-children'}>
                    <span role="button" onClick={this.toggleProfileClass}><span className="icon af-profile"></span>Twój Profil</span>
                    <li>
                      <NavLink to="/my-account">Zobacz swój profil</NavLink>
                    </li>
                    <li>
                      <NavLink to="/edit-my-account">Edytuj profil</NavLink>
                    </li>
                    <li>
                      <NavLink to="/my-gallery">Twoja galeria</NavLink>
                    </li>
                    <li>
                      <span>Ilość odwiedzin profilu <samp>0</samp></span>
                    </li>
                    <li className="premium">
                      <NavLink to="/visit" disabled>Kto mnie odwiedził?</NavLink>
                    </li>
                    <li>
                      <NavLink to="/call-in">Otrzymane zaproszenia</NavLink>
                    </li>
                    <li>
                      <NavLink to="/call-out">Wysłane zaproszenia</NavLink>
                    </li>
                    <li>
                      <NavLink to="/friends">Lista znajomych</NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <NavLink to="/new-friends"><span className="icon af-search"></span>Poznaj nowe osoby</NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <NavLink to="/chat"><span className="icon af-messages"></span>Chat</NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <NavLink to="/notyfications"><span className="icon af-bell"></span>Powiadomienia</NavLink>
                    </li>
                  </ul>
                  <ul className={this.state.activePremium ? 'has-children active':'has-children'}>
                    <span role="button" onClick={this.togglePremiumClass}><span className="icon af-star"></span>Premium</span>
                    <li>
                      <NavLink to="/pro-subscribe">Subskrypcja</NavLink>
                    </li>
                    <li>
                      <NavLink to="/pro-settings">Ustawienia</NavLink>
                    </li>
                  </ul>
                  <ul className={this.state.activeSettings ? 'has-children active':'has-children'}>
                    <span role="button" onClick={this.toggleSettingsClass}><span className="icon af-cog"></span>Ustawienia</span>
                    <li>
                      <NavLink to="/report-user">Zgłoś użytkownika</NavLink>
                    </li>
                    <li>
                      <NavLink to="/report-bug">Zgłoś usterkę</NavLink>
                    </li>
                  </ul>
                  <ul className={this.state.activeInfo ? 'has-children active':'has-children'}>
                    <span role="button" onClick={this.toggleInfoClass}><span className="icon af-search"></span>Informacje</span>
                    <li>
                      <a href="https://feelio.pl/polityka-prywatnosci/" target="_blank" rel="noreferrer">Polityka prywatności</a>
                    </li>
                    <li>
                      <a href="https://feelio.pl/regulamin" target="_blank" rel="noreferrer">Regulamin</a>
                    </li>
                    <li>
                      <a href="https://feelio.pl/platnosci-i-reklamacje" target="_blank" rel="noreferrer">Płatności i reklamacje</a>
                    </li>
                    <li>
                      <a href="https://feelio.pl/kontakt/" target="_blank" rel="noreferrer">Kontakt</a>
                    </li>
                  </ul>
                  <ul>
                      <li>
                        <NavLink to="/logout"><span className="icon af-heart"></span>Wyloguj</NavLink>
                      </li>
                    </ul>
                  
                </div>
                <div className={ this.state.loggedIn ?'u-col-12 u-col-md-9 content position-relative':'u-col-12 content position-relative'}>
                    <Switch>
                      <Route exact path="/">
                        { this.state.loggedIn ? <Redirect to="/all-posts" /> : <Redirect to="/login" /> }
                        { console.log( this.state.loggedIn ) }
                      </Route>
                      <Route path="/all-posts" component={PostList} />
                      <Route path="/login" component={LogIn} />
                      <Route path="/logout" component={LogOut} />
                      <Route path="/onbording" component={Onbording} />
                      <Route path="/my-posts" component={MyPosts} />
                      <Route path="/create-post" component={CreatePost} />
                      <Route path="/my-account" component={MyAccount} />
                      <Route path="/edit-my-account" component={EditMyAccount} />
                      <Route path="/my-gallery" component={MyGallery} />
                      <Route path="/visit" component={Visit} />
                      <Route path="/call-in" component={CallIn} />
                      <Route path="/call-out" component={Callout} />
                      <Route path="/friends" component={Friends} />
                      <Route path="/new-friends" component={NewFriends} />
                      <Route path="/chat" component={Chat} />
                      <Route path="/room/:from_id/:to_id" component={Room} />
                      <Route path="/notyfications" component={Notyfications} />
                      <Route path="/pro-subscribe" component={Subscribe} />
                      <Route path="/pro-settings" component={Settings} />
                      <Route path="/report-user" component={ReportUser} />
                      <Route path="/report-bug" component={ReportBug} />
                      <Route path="/post/:post_id" component={PostView} />
                      <Route path="/users/:user_id" component={UserView} />
                    </Switch>
                  </div>
            </div>
          </div>
        </Router>
      );
  }
}