import { Component } from "react";

export default class ReportBug extends Component{

    render(){
        return(
            <div className="">
                Zgłoś usterkę
            </div>
        )
    }
}