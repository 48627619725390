import React from "react";
import Step1 from "./onbording/step1";
import Step2 from "./onbording/step2";
import Step3 from "./onbording/step3";
import Step4 from "./onbording/step4";
import Step5 from "./onbording/step5";
import Step6 from "./onbording/step6";
import Step7 from "./onbording/step7";
import Step8 from "./onbording/step8";
import Step9 from "./onbording/step9";
import Step10 from "./onbording/step10";
import Step11 from "./onbording/step11";
import Step12 from "./onbording/step12";
import Step13 from "./onbording/step13";
import Step14 from "./onbording/step14";
import Step15 from "./onbording/step15";
import Step16 from "./onbording/step16";
import {
  Redirect
} from "react-router-dom";
import axios from 'axios';
import Alerts from "./alerts";
import config from '../config.json'

class Onbording extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentStep:1,
            user_nick: '',
            preview: '',
            src: '',
            user_zodiak:'',
            user_birth:'',
            user_state:'',
            user_city:'',
            user_sex:'',
            user_desc:'',
            user_work:'',
            user_interest:[],
            user_education:'',
            user_languages:'',
            user_country:'',
            user_religion:'',
            user_sex_intrest:'',
            user_status:'',
            user_children:'',
            user_addictions:[],
            user_find:'',
            user_body:'',
            user_search:'',
            user_active:false,
            button_active: true,
            show_error: false,
            error_msg: ''
        }
    }
    componentDidMount(){
      axios
      .get(`${config.dbURL}/wp-json/fac/v1/users/${localStorage.getItem('userID')}`)
      .then(res =>{
          this.setState({
              fillData:res.data.user_active
          })
          localStorage.setItem('fillData', res.data.user_active)
      })
      .catch(err => {
          console.warn(err)
      })
    }
    handleChange = event => {
        const {name, value} = event.target
        if( name === 'user_birth' ){
          let currentFullDate = Date.now() - new Date(value).getTime();
          let curentDateConvert = new Date(currentFullDate);
          let userAge = Math.abs( curentDateConvert.getUTCFullYear() ) - 1970;
          if( userAge < 18 ){
            this.setState({
              button_active: false,
              show_error: true,
              error_msg: 'Wiek minimalny wynaosi 18 lat'
            })
            console.log( 'error: you mast have 18 years old' )
          }else{
            this.setState({
              button_active: true,
              show_error: false
            })
          }
        }
        this.setState({
          [name]: value
        })    
    }
    handleSubmit = event => {
        event.preventDefault()
        const {preview, user_zodiak, user_birth, user_state, user_country, user_city, user_sex, user_desc, user_work, user_interest, user_education, user_languages, user_religion, user_sex_intrest, user_status, user_children, user_addictions, user_find, user_body, user_search, user_nick, user_active} = this.state;
        alert(`Your registration detail: \n 
                Birth: ${user_birth} \n 
                Miasto: ${user_city}\n
                Płeć: ${user_sex}\n
                Avatar: ${preview}\n
                Opis: ${user_desc}\n
                Zawód: ${user_work}\n
                Zainteresowania: ${user_interest}\n
                Edukacja: ${user_education}\n
                Wyznanie: ${user_religion}\n
                Interesuje: ${user_sex_intrest}\n
                Status: ${user_status}\n
                Status: ${user_children}`)
    }
    handleChangeIntrest = event =>{
      let {user_interest} = this.state
      user_interest.push({value:event.target.value})
      this.setState({
          user_interest
      });
    }

    handleChangeAddictions = event =>{
        let {user_addictions} = this.state
        user_addictions.push({value:event.target.value})
        this.setState({
            user_addictions
        });
    }

    handleChangeZodiak = (user_zodiak) => {
        this.setState({user_zodiak});
    };

    handleChangeSex = (user_sex) => {
        this.setState({user_sex});
    };

    handleChangeEducation = (user_education) => {
        this.setState({user_education});
    };

    handleChangeLanguage = (user_languages) => {
        this.setState({user_languages});
    };

    handleSelectCountry = (user_country) => {
        this.setState({user_country});
    };

    handleSelectSexIntrest = (user_sex_intrest) => {
        this.setState({user_sex_intrest});
    };

    handleSelectStatus = (user_status) => {
        this.setState({user_status});
    };

    handleSelectChildren = (user_children) => {
        this.setState({user_children});
    };

    handleSelectBody = (user_body) => {
        this.setState({user_body});
    };
    _next = () => {
        let currentStep = this.state.currentStep
        const {preview, user_zodiak, user_birth, user_state, user_country, user_city, user_sex, user_desc, user_work, user_interest, user_education, user_languages, user_religion, user_sex_intrest, user_status, user_children, user_addictions, user_find, user_body, user_search, user_nick, user_active} = this.state;

        let userData = {
            description: user_desc,
            user_avatar: preview,
            user_city: user_city,
            user_birth: user_birth,
            user_sex: user_sex,
            user_work: user_work,
            user_interest: user_interest,
            user_education: user_education,
            user_religion: user_religion,
            user_sex_intrest: user_sex_intrest,
            user_status: user_status,
            user_children: user_children,
            user_zodiak: user_zodiak,
            user_body: user_body,
            user_country: user_country,
            user_language: user_languages,
            user_goal: user_find,
            user_addictions: user_addictions,
            user_state: user_state,
            user_nick: user_nick,
            user_active: user_active
        }

        currentStep = currentStep >= 16? 17: currentStep + 1

        axios
          .post(`${config.dbURL}/wp-json/fac/v1/users/${localStorage.getItem('userID')}`, userData)
          .then(res => {
              //console.log(res)
            })
            .catch(err => {
              console.log(err)
            })
              
        if( currentStep > 2 ){
          this.setState({
            user_active:true
          })
        }
        if( currentStep === 2 ){
          localStorage.setItem('userName',this.state.user_nick)
        }
        if( currentStep > 3 ){
          localStorage.setItem('fillData','1')
        }
        this.setState({
            currentStep: currentStep,
            show_error:false,
            button_active: true
          })
        }
        _prev = () => {
          let currentStep = this.state.currentStep
          currentStep = currentStep <= 1? 1: currentStep - 1
          this.setState({
            currentStep: currentStep,
            show_error:false,
            button_active: true
        })
    }
    previousButton() {
      let currentStep = this.state.currentStep;
      if(currentStep !==1){
        return (
          <button 
            className="btn btn-default-outline prev_step" 
            type="button" onClick={this._prev}>
            Wstecz
          </button>
        )
      }
      return null;
    }  
    nextButton(){
      let currentStep = this.state.currentStep;
      let buttonActive = (this.state.button_active)?null:'disabled';
      if(currentStep < 16 && this.state.button_active ){
        return (
          <button 
            className="btn btn-default next_step" 
            type="button" 
            onClick={this._next} 
          >
            Dalej
          </button>        
        )
      }else{
        return (
          <button 
            className="btn btn-default next_step" 
            type="button" 
            onClick={this._next} 
            disabled
          >
            Dalej
          </button>        
        )

      }
      return null;
    }
    stepAbort(){
      let currentStep = this.state.currentStep;
      if(currentStep >= 5 && currentStep < 16){
        return (
          <button 
            className="btn btn-link abort mt-3" 
            type="button" onClick={this._next}>
            Pomiń ten krok
          </button>        
        )
      }
      return null;
    }
    showErrors(){
      if( this.state.show_error ){
        return(
          <Alerts type="warning" message={this.state.error_msg} />
        )
      }
    }
    sendForm(){
      let currentStep = this.state.currentStep;
      if(currentStep === 16){
        return (
          <button 
            className="btn btn-default abort" 
            type="button" onClick={this._next}>
            Przejdź do aplikacji
          </button>        
        )
      }
      return null;
    }
    displayProgres(step){
      const allSteps = 16
      let progress = Math.round((step/allSteps)*100)
      //console.log(step)
      //console.log(progress)
      if(step >= 4){
        return(
          <div className="progress_bar">
            <div className="progress" style={{width:progress+"%"}}>

            </div>
          </div>
        )
      }
    }
    display(){
      let currentStep = this.state.currentStep;
      if( currentStep <=16 ){
        return(
          <div className="u-container">
            <div className="u-row h-94 align-items-center">
                <div className="u-col-10 u-col-lg-4 mx-auto text-center">
                  <div className="onbording">
                    <div className="form">
                    {this.displayProgres(this.state.currentStep)}
                    <Step1 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChange}
                      user_nick={this.state.user_nick}
                    />
                    <Step2 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChange}
                      user_birth={this.state.user_birth}
                    />
                    <Step3 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChange}
                      user_city={this.state.user_city}
                    />
                    <Step4 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChange}
                      user_nick={this.state.user_nick}
                    />
                    <Step5 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChange}
                      user_sex={this.state.user_sex}
                    />
                    <Step6 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChange}
                      user_avatar={this.state.preview}
                    />
                    <Step7 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChange}
                      user_desc={this.state.user_desc}
                    />
                    <Step8 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChange}
                      user_work={this.state.user_work}
                    />
                    <Step9 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChangeIntrest}
                      user_interest={this.state.user_interest}
                    />
                    <Step10 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChangeEducation}
                      user_education={this.state.user_education}
                    />
                    <Step11 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleSelectCountry}
                      user_religion={this.state.user_country}
                    />
                    <Step12 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleSelectBody}
                      user_religion={this.state.user_body}
                    />
                    <Step13 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleChange}
                      user_religion={this.state.user_religion}
                    />
                    <Step14 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleSelectSexIntrest}
                      user_sex_intrest={this.state.user_sex_intrest}
                    />
                    <Step15
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleSelectStatus}
                      user_status={this.state.user_status}
                    />
                    <Step16 
                      currentStep={this.state.currentStep} 
                      handleChange={this.handleSelectChildren}
                      user_children={this.state.user_children}
                    />
                    {this.showErrors()}
                    {this.previousButton()}
                    {this.nextButton()}
                    {this.stepAbort()}
                    {this.sendForm()}
                    </div>
                  </div>
                </div>
            </div>
        </div>
        )
      }else if(currentStep === 17){
        window.location.reload(true);
      }
    }
    render(){
        console.log(this.state.currentStep);
        if( !localStorage.getItem('userloggedIn') ){
          return <Redirect to="/login" />
        }else if( localStorage.getItem('fillData') === '1' ){
          window.location.reload(true);
        }else if( this.setState.currentStep === 17 ){
          window.location.reload(true);
        }
        return(
            <>
              {this.display()}     
            </>
        );
    }
};

export default Onbording;