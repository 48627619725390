import { Component } from "react";

export default class UserView extends Component{
    render(){
        return(
            <div>
                wyświetlam użytkownika ID: {this.props.match.params.user_id}
            </div>
        )
    }
}