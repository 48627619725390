import { Component } from "react";

export default class Subscribe extends Component{

    render(){
        return(
            <div className="">
                Subskrypcja
            </div>
        )
    }
}